/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, Plyr, gsap) => {

		/*
		|
		| Constants
		|-----------
		*/
		const $body = $('body')

		$body.on('loaderEnd', () => console.log('ended'))

		document.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))


		/*
		|
		| Scroll menu fixed
		|-----------------
		*/
		let prevScrollpos = window.pageYOffset;
		window.onscroll = function () {
			let currentScrollpos = window.pageYOffset;
			if (prevScrollpos > currentScrollpos) {
				document.getElementById("header").style.top = "0";
			} else {
				document.getElementById("header").style.top = "-130px";
			}
			if (currentScrollpos > 50) {
				jQuery("header").removeClass('top');
				jQuery(".vignette").addClass('bottom');
			} else {
				jQuery("header").addClass('top');
				jQuery(".vignette").removeClass('bottom');
			}
			prevScrollpos = currentScrollpos;

			if (currentScrollpos < 50) {
				document.getElementById("header").style.top = "0";
			}
		}

		/*
		|
		| Submenu services
		|-----------------
		*/
		$('.submenu-services').hover(function (l) {
			$('.site-container').find('.sm-menu').first().stop(true, true).slideDown(500);
			l.preventDefault();
		}, function (l) {
			$('.site-container').find('.sm-menu').first().stop(true, true).slideUp(500);
			l.preventDefault();
		});


		$(".sm-menu .link").mouseover(function () {
			jQuery(this).stop(true, true).animate({ 'opacity': '1' }, 300).siblings().animate({ 'opacity': '0.6' }, 100);
		});
		$(".sm-menu .link").mouseout(function () {
			jQuery('.sm-menu .link').stop(true, true).animate({ 'opacity': '1' }, 100)
		});



		/*	
		|
		| Plyr
		|-----------------
		*/
		const $customPlyrVideo = jQuery('.custom-plyr-video');
		$.each($customPlyrVideo, function () {
			const $video = $(this);
			const $videoPoster = $video.find('.item-poster');
			const $videoIcon = $video.find('.item-play-icon');
			const videoId = $video.data('id');
			const type = $video.data('type');
			let player = new Plyr(`#${videoId}`);
			$(`#${videoId}`).addClass('custom-plyr-video');

			player.on("play", (event) => {
				let delayInMilliseconds = 500;
				setTimeout(function () {
					jQuery('.item-poster').css('opacity', '0');
				}, delayInMilliseconds);
			});
		});

		/*
		|
		| Chiffres
		|-----------------
		*/
		$('.key-figures').each(function () {
			const block = {
				wrapper: $(this),
				numbers: $(this).find('.number-counter'),
			};
			if (block.numbers.length) {
				block.timeline = gsap.timeline({
					scrollTrigger: {
						trigger: block.wrapper,
						start: 'top 80%'
					}
				});
				block.numbers.toArray().forEach((number) => {
					let counter = $(number);
					let count = counter.data('count').toString().replace(',', '.').replace(' ', '');
					let zero = { val: 0 };
					let decimals = count.split('.')[1];
					let toFixed = decimals ? decimals.length : false;
					count = toFixed ? parseFloat(count) : parseInt(count);
					block.timeline.to(zero, {
						duration: 1,
						val: count,
						ease: 'power2.out',
						onUpdate: function () {
							let val = 0;
							if (toFixed) {
								let parts = zero.val.toFixed(toFixed).toString().split('.');
								parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
								val = parts.join(',')
							} else {
								val = Math.round(zero.val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
							}
							counter.text(val);
						}
					}, '-=.2');
				});
			}
		});

		/*
		| 
		| Home liste animes
		|-----------
		*/
		jQuery(".elem-list").on("mouseover", function () {
			jQuery(".elem-list").removeClass('active');
			jQuery(this).addClass('active');

			let dataimg = $(this).data('img');
			jQuery($body).find('.img-list').css("background-image", "url(" + dataimg + ")");
		});

		/*
		| 
		| Slider Rejoindre
		|-----------
		*/
		let mySwiperRejoindre = new Swiper(".swiper-rejoindre", {
			slidesPerView: 3.5,
			spaceBetween: 40,
			loop: true,
			speed: 1000,
			autoplay: {
				delais: 2000,
			},
			centeredSlides: true,
			breakpoints: {
				0: {
					slidesPerView: 1.5
				},
				768: {
					slidesPerView: 1.5
				},
				992: {
					slidesPerView: 2.5
				},
				1200: {
					slidesPerView: 3.5,
					centeredSlides: true,
				},
			},
			navigation: {
				nextEl: ".button-next",
				prevEl: ".button-prev",
			}
		});

		/*
		| 
		| Slider Actus
		|-----------
		*/
		let mySwiperActus = new Swiper(".swiper-actus", {
			slidesPerView: 3.5,
			spaceBetween: 32,
			speed: 1000,
			breakpoints: {
				0: {
					slidesPerView: 1.2
				},
				768: {
					slidesPerView: 1.2,
				},
				992: {
					slidesPerView: 2.5
				},
				1200: {
					slidesPerView: 3.5,
				},
			},
			navigation: {
				nextEl: ".button-next",
				prevEl: ".button-prev",
			}
		});

		// progress bar
		function percent() {
			let percent = ((mySwiperActus.activeIndex + 1) / mySwiperActus.slides.length) * 100 + "%";
			jQuery('.progressbar').css("width", percent);
		}
		percent();
		mySwiperActus.on('slideChange', function () {
			percent();
		});


		/*
		| 
		| Slider Engagements
		|-----------
		*/
		let swiperengagements = new Swiper(".swiper-engagements", {
			slidesPerView: 1.5,
			spaceBetween: 80,
			speed: 1500,
			breakpoints: {
				0: {
					slidesPerView: 1
				},
				768: {
					slidesPerView: 1,
				},
				1200: {
					slidesPerView: 1.5,
				},
			},
			navigation: {
				nextEl: ".button-next-eng",
				prevEl: ".button-prev-eng"
			}
		});

		let swiperengagements2 = new Swiper(".swiper-engagements2", {
			slidesPerView: 1,
			spaceBetween: 80,
			speed: 1500
		});

		swiperengagements.on("slideChange", () => {
			swiperengagements2.slideTo(swiperengagements.activeIndex);
		});



		/*
		| 
		| Slider Témoignages
		|-----------
		*/
		$(".swiper-temoignages").each(function (i) {
			let swipertemoignages = new Swiper($(this)[0], {
				slidesPerView: 2.5,
				spaceBetween: 80,
				speed: 1000,
				centeredSlides: true,
				breakpoints: {
					0: {
						slidesPerView: 1.2
					},
					768: {
						slidesPerView: 1.2,
					},
					992: {
						slidesPerView: 2.5
					},
					1200: {
						slidesPerView: 2.5,
					},
				},
				navigation: {
					nextEl: $(".button-next-tem")[i],
					prevEl: $(".button-prev-tem")[i],
				}
			});
			function percentTemoignages() {
				let percent = ((swipertemoignages.activeIndex + 1) / swipertemoignages.slides.length) * 100 + "%";
				jQuery('.progressbar-tem').css("width", percent);
			}
			percentTemoignages();
			swipertemoignages.on('slideChange', function () {
				percentTemoignages();
			});
		});

		/*
		| 
		| Slider emplois
		|-----------
		*/
		$(".swiper-emplois").each(function (i) {
			let swiperemplois = new Swiper($(this)[0], {
				slidesPerView: 2.5,
				spaceBetween: 30,
				speed: 1000,
				centeredSlides: true,
				breakpoints: {
					0: {
						slidesPerView: 1.2
					},
					768: {
						slidesPerView: 1.2,
					},
					992: {
						slidesPerView: 2.5
					},
					1200: {
						slidesPerView: 2.5,
					},
				},
				navigation: {
					nextEl: $(".button-next-emplois")[i],
					prevEl: $(".button-prev-emplois")[i],
				}
			});
			function percentemplois() {
				let percent = ((swiperemplois.activeIndex + 1) / swiperemplois.slides.length) * 100 + "%";
				jQuery('.progressbar-emplois').css("width", percent);
			}
			percentemplois();
			swiperemplois.on('slideChange', function () {
				percentemplois();
			});
		});

		/*
		| 
		| Slider diaporama
		|-----------
		*/
		$(".swiper-diaporama").each(function (i) {
			let swiperemplois = new Swiper($(this)[0], {
				slidesPerView: 1,
				spaceBetween: 30,
				speed: 1000,
				centeredSlides: true,
				navigation: {
					nextEl: $(".button-next-diaporama")[i],
					prevEl: $(".button-prev-diaporama")[i],
				}
			});
		});

		/*
		| 
		| Liste question
		|-----------
		*/
		jQuery(".elem-list-questions").on("mouseover", function () {
			jQuery(".elem-list-questions").removeClass('active');
			jQuery(this).addClass('active');

			let datatext = $(this).data('text');

			jQuery('.content-text').text(datatext);

		});

	}
}
