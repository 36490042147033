/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app) => {
		app.dump('single.js');

	}
}

