/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {


        /*
        |
        | Input quantity step by 100
        |----------------------------
        */
        const productRadioLitres = document.querySelector(".productRadioLitres input[type=number]");
        productRadioLitres.setAttribute("step", 100);

        productRadioLitres.addEventListener('focusout', function (e) {
            var result = Math.round(this.value / 100) * 100; this.value = (result) ? result : this.min;
        });


    }
}
