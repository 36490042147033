/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
import Swiper, { Navigation, Autoplay } from 'swiper';
import gsap from 'gsap';
import SplitText from '@lib/gsap-pro/SplitText';
import ScrollTo from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

Swiper.use([Navigation, Autoplay]);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import MaterializeForm from '@components/materialize-form';
import Kira from '@components/kira';
import Menu from '@components/menu';
import Plyr from '@components/plyr.min.js';

/*
|
| Importing Utils
|-------------------
*/
import * as app from '@utils/global';
import Router from '@utils/router';
import Ajax from '@utils/ajax';

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main';
import news from '@pages/news';
import animations from '@pages/animations';
import single from '@pages/single';
import contact from '@pages/contact';
import actualites from '@pages/actualites.js';
import form from '@pages/form.js';
/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    'file': animations,
    'dependencies': [app, gsap, ScrollTrigger, SplitText, Menu, Kira]
  },
  {
    'file': main,
    'dependencies': [app, Swiper, Plyr, gsap]
  },
  {
    'file': news,
    'dependencies': [app, gsap, Ajax],
    'resolve': '#page-news-archive'
  },
  {
    'file': actualites,
    'dependencies': [app],
    'resolve': '#page-actualites-archive'
  },
  {
    'file': single,
    'dependencies': [app],
    'resolve': '.single-actualite'
  },
  {
    'file': contact,
    'dependencies': [app, MaterializeForm],
    'resolve': '#page-contact'
  },
  {
    'file': form,
    'dependencies': [app],
    'resolve': '#page-form'
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

